import { createTheme, ThemeOptions } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    custom: {
      [key: string]: string;
    };

  }
  interface PaletteOptions {
    custom?: {
      [key: string]: string;
    }
  }
}
const themeOptions: ThemeOptions = {
  typography: {
    fontFamily: 'Inter',
    body1: {
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.3px',
      fontWeight: 400,
    },
    body2: {
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.3px',
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.2px',
      fontWeight: 400,
    },
    h1: {
      fontSize: '36px',
      lineHeight: '44px',
      fontWeight: 500,
      letterSpacing: "0.2px"
    },
    h2: {
      fontSize: '34px',
      lineHeight: '44px',
      fontWeight: 600,
    },
    h3: {
      fontSize: '28px',
      lineHeight: '36px',
      fontWeight: 500,
    },
    h4: {
      fontSize: '22px',
      lineHeight: '28px',
      fontWeight: 600,
    },
    caption: {
      fontSize: '12px',
      lineHeight: '20px',
    },
    button: {
      fontSize: '14px',
      lineHeight: '26px',
      letterSpacing: '0.25px',
      fontWeight: 500,
      textTransform: 'none',
    },
  },
  palette: {
    primary: {
      main: '#F16300',
      dark: '#FE8836',
    },
    background: {
      default: '#ffffff',
      paper: '#F7F7F7' 
    },
    text: {
      primary: '#000000', 
      secondary: '#4C545B',
    },
    custom: {
      textDarkBlue: '#1F2932',
      subTitleModal: '#1C3C6C',
    },
    error: {
      main: '#D32F2F',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  components: {
    //TextField
    MuiTextField: {
      styleOverrides: {
        root: {
          fontFamily: "Inter, sans-serif",
          "& .MuiInputLabel-root": {
            fontSize: "16px",
            fontWeight: 500,
            color: "#1F293299",
            lineHeight: "20px",
            letterSpacing: "0.15px",
            transform: "translate(13px, 10px)",
            transition: "transform 0.2s ease, color 0.2s ease",
            "&.Mui-focused": {
              fontSize: "12px",
              color: "#1C3C6C",
              transform: "translate(0, -22px)",
            }, 
            "&.MuiFormLabel-filled": {
              fontSize: "12px",
              transform: "translate(0, -22px)",
            },
          },
          "& .MuiInputBase-input": {
            padding: "8px 8px 0px 8px",
            lineHeight: "24px",
            letterSpacing: "0.2px",
            overflow: "auto",
            "&::-webkit-scrollbar": {
              width: "15px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#BDBDBD",
              borderRadius: "100px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#EEEEEE",
              borderLeft: "1px solid var(--divider, #0000001F)",
            },
            "&::-webkit-scrollbar-button": {
              display: "none",
            },
          },
          "& .MuiInputBase-root": {
            fontFamily: 'Inter, sans-serif',
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "0.2px",
            borderRadius: 8,
            border: "1px solid #CFCFCF",
            color: "#1F2932",
            minHeight: "40px", 
            padding: "0 0 0 8px", 
            display: "flex",
            "&:hover": {
              borderColor: "#1C3C6C",
            },
            "&.Mui-focused": {
              borderColor: "#1C3C6C",
            },
            "& input": {
              padding: "2px 8px 2px 8px",
            },
            "& input:-webkit-autofill": {
              WebkitBackgroundClip: "text"
            },
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none", 
          },
          "&:focus-within .MuiInputLabel-root": {
            color: "#1C3C6C", 
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottom: "1px solid #D9D9D9",
        },
        indicator: {
          backgroundColor: "#1C3C6C", 
          height: "2px", 
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: "#00000099", 
          fontWeight: 500,
          fontSize: "20px",
          lineHeight: "24px",
          letterSpacing: "0.2px",
          "&.Mui-selected": {
            color: "#1C3C6C" 
          },
        },
      },
    },
  },
};

export const theme = createTheme(themeOptions);
