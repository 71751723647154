import React from 'react';
import type { SVGProps } from 'react';

type MaterialSymbolsTableProps = SVGProps<SVGSVGElement> & {
    active: boolean;
  };

  export const MaterialSymbolsTable: React.FC<MaterialSymbolsTableProps> = ({
    active,
    ...props
  }) => {
	return (
    <svg xmlns="http://www.w3.org/2000/svg" width={26} height={26} viewBox="0 0 24 24" {...props}><path fill={!active ? "none" : "#1C3C6C"} stroke={!active ? "#1F293299": "none"} d="M11 16H3v3q0 .825.588 1.413T5 21h6zm2 0v5h6q.825 0 1.413-.587T21 19v-3zm-2-2V9H3v5zm2 0h8V9h-8zM3 7h18V5q0-.825-.587-1.412T19 3H5q-.825 0-1.412.588T3 5z"></path></svg>
  );
};