import { useEffect} from 'react';
import { getErrorMessage } from 'utils/getErrorMessage';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import type { SerializedError } from '@reduxjs/toolkit';

type FieldErrorMap = Record<string, string>;

interface HandleErrorOptions {
  setFieldErrors?: (errors: FieldErrorMap) => void;
  setGlobalErrors?: (errors: string | null) => void;
  reset?: () => void;
}

export const useHandleError = (
  error?: FetchBaseQueryError | SerializedError,
  { setFieldErrors, setGlobalErrors, reset }: HandleErrorOptions = {}
) => {
  useEffect(() => {
    if (error) {
      const { fieldErrors, globalError } = getErrorMessage(error);

      if (setFieldErrors) {
        setFieldErrors(fieldErrors);
      }

      if (setGlobalErrors) {
        setGlobalErrors(globalError);
      }

      if (reset) reset();
    }
  }, [error, setFieldErrors, setGlobalErrors, reset]);
};
