import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  ChartOptions,
  Chart,
} from "chart.js";
import { Box, Typography, Grid, Stack } from "@mui/material";
import { Tooltip as MITooltip } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ChartDataLabels from 'chartjs-plugin-datalabels';


const topLabels = {
  id: "topLabels",
  afterDatasetsDraw(chart: Chart, args: any, pluginOptions: any) {
    const { ctx } = chart;
    ctx.save;

    chart.data.datasets.forEach((dataset, datasetIndex) => {
      const meta = chart.getDatasetMeta(datasetIndex);

      if (
        dataset.label === ""
      ) {
        return;
      }

      meta.data.forEach((bar, index) => {
        const value = dataset.data[index];

        const x = bar.x;
        const y = bar.y; 

        const labelY = y - 72;

        ctx.beginPath();
        ctx.moveTo(x, labelY + 32);
        ctx.lineTo(x - 8, labelY + 20);
        ctx.lineTo(x + 8, labelY + 20);
        
        ctx.closePath();
        ctx.fillStyle = "#697184";
        ctx.fill();
      });
    });
  },
};

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

interface StackedBarChartProps {
  importedDataPercentage: number;
  enrichedDataPercentage: number;
  expectedSize: number;
  minSize: number,
  maxSize: number
}

export const StackedBarChart: React.FC<StackedBarChartProps> = ({
  importedDataPercentage,
  enrichedDataPercentage,
  expectedSize,
  minSize,
  maxSize
}) => {
  const hasData = importedDataPercentage !== 0 || enrichedDataPercentage !== 0;

  const data = {
    labels: [""],
    datasets: [
      {
        label: "Imported data",
        data: [importedDataPercentage],
        backgroundColor: "#1C3C6C",
      },
      {
        label: "Enriched data",
        data: [enrichedDataPercentage],
        backgroundColor: "#FE8836",
      },
      {
        label: "", 
        data: [100],
        backgroundColor: "#f5f5f5",
      },
    ],
  };

  const options: ChartOptions<"bar"> = {
    indexAxis: "y",
    responsive: true,
    layout: {
      padding: {
        top: 50,
        bottom: 10,
        left: 36,
        right: 36,
      }
  },
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        anchor: "end",
        align: 'top',
        offset: 50,
        clip: false,
        padding: {
          right: 8,
          left: 10,
          top: 7,
          bottom: 5
        },
        display: (context) => {
          return context.dataset.label !== "";
        },
        textAlign: "center",
        formatter: (value, context) => {
          return context.dataset.data[0] + '%';
        },
        backgroundColor: "#697184",
        borderRadius: 4,
        color: "#EEEEEE",
        font: {
          size: 14,
          weight: 600,
          lineHeight: "12px",
          family: "Inter"
        },
      },
      legend: {
        display: true,
        labels: {
          color: "#697184",
          font: {
            size: 14,
            family: "Inter",
          },
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      x: {
        offset: false,
        beginAtZero: true,
        max: 100,
        
        ticks: {
          stepSize: 20,
          callback: (value) => `${value}`,
          font: {
            size: 12,
            weight: 500,
            lineHeight: "14.52px",
            family: "Inter",
          },
        },
        grid: {
          display: true,
          color: "#e0e0e0",
        },
      },
      y: {
        offset: true,
        border: {
          display: false
        },
        beginAtZero: true,
        stacked: true,
        grid: {
          display: false,
          drawOnChartArea: true, 
          color: "#e0e0e0",
        },
      },
    },
  };

  return (
    <Box>
      <Grid container justifyContent="space-between" alignItems="center"  >
        <Grid item> 
          <Typography variant="button" color="#1F293299" >
            Expected size
          </Typography>
          <Box sx={{
            flexDirection: 'row', display: "flex",  paddingTop: 1, gap: 1
          }}>
            <Typography color="#1F2932" 
                      
                        sx={{
                            fontSize: "20px",
                            lineHeight: "36px",
                            fontWeight: 500,
                          
            }}>
              {expectedSize} members
            </Typography>
            <MITooltip 
                placement="bottom"
                sx={{
                  background: "#596177E5",
                  fontSize: "12px",
                  lineHeight: "16px"
                }} 
                arrow
                title={
                  <Typography>
                    Thresholds for such company size: <br />
                    <strong>min</strong> - {minSize} members <br />
                    <strong>max</strong> - {maxSize} members
                  </Typography>
                }>
                <HelpOutlineIcon sx={{color: "#1C3C6C"}} />
            </MITooltip>
          </Box>
        </Grid>
        <Grid item sx={{ height: '70px' }}>
          <Box display="flex" 
               alignItems="flex-end" 
               justifyContent="flex-end" 
               gap={4}
               sx={{ height: '100%' }}>
            <Box display="flex" alignItems="center" gap={2}>
              <Box
                sx={{
                  width: 24,
                  height: 20,
                  backgroundColor: "#1C3C6C",
                }}
              />
              <Typography variant="body1" color="#1F2932">Imported data</Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={2}>
              <Box
                sx={{
                  width: 24,
                  height: 20,
                  backgroundColor: "#FE8836",
                }}
              />
              <Typography variant="body1" color="#1F2932">Enriched data</Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
        {hasData ? (
        <Box
          sx={{
            position: "relative",
            overflow: "visible",
            height: "170px",
            paddingTop: 3,
            width: "calc(100% + 85px)",
            marginLeft: "-50px",
          }}
        >
          <Bar id="chart-bar" data={data} options={options} plugins={[ChartDataLabels, topLabels]} />
        </Box>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="170px"
          sx={{
            borderRadius: 4,
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: "#697184",
              fontSize: "16px",
              fontWeight: 500,
            }}
          >
            No data available
          </Typography>
        </Box>
      )}
    </Box>
  );
};
