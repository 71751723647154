import React from 'react';
import { AppBar, Toolbar, Box } from '@mui/material';
import { CustomLogo } from 'components/images/Logo';

export const LoginHeader: React.FC = () => {
  return (
    <AppBar position="static" 
            color="default" 
            sx={{
                background: "white", 
                height: "74px",
                boxShadow: '0',
                px: 2,
                justifyContent: 'center', 
            }}>
      <Toolbar>
        <Box>
          <CustomLogo/>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
