import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './baseQuery';
import type {
  LoginRequest,
  LoginResponse,
} from 'types/apiTypes';
import { RequestMethods } from 'types/enums';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginRequest>({
      query: (body) => ({
        url: '/auth/login',
        method: RequestMethods.POST,
        body,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
} = authApi;