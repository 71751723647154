import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box, Container } from '@mui/material';
import { Header } from 'components/features/Header';

const Layout: React.FC = () => {
  return (
    <Box>
      <Header />  
      <Container component="main" maxWidth="xl"> 
        <Outlet />
      </Container>
    </Box>
  );
};

export default Layout;
