import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { CustomButton } from './CustomButton';


interface ImportButtonProps {
  onClick: () => void;
  ButtonText: string;
}

export const ImportButton: React.FC<ImportButtonProps> = ({ onClick, ButtonText }) => {
  const customSize = ButtonText === 'Add company and contacts data' ? 'xl' : 'l';

  return (
    <CustomButton onClick={onClick} 
                  customSize={customSize}  
                  type="submit"
                  customColor="#F16300" 
                  customGradientColor="#FE8836">
      <AddIcon fontSize='small'/> 
      {ButtonText}
    </CustomButton>
  );
};
