import React, { memo, useMemo } from 'react';
import type {
  ChartOptions,
} from 'chart.js';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import { ChartItem } from 'types/companyTypes';
import { EllipsisTooltip } from './CustomTooltip';

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

interface ChartRowProps {
  employee: ChartItem;
}

const extendBarsPlugin = {
  id: 'extendBars',
  beforeDraw(chart: ChartJS) {
    const { ctx, chartArea, scales: { x } } = chart;
    const lineWidth = 1;
    const lineColor = '#D9D9D9';
    const padding = 12;
    const numLines = 4;
    const step = (chartArea.right - chartArea.left - padding * 2) / (numLines + 1);

    ctx.save();
    ctx.lineWidth = lineWidth;
    ctx.strokeStyle = lineColor;

    for (let i = 1; i <= numLines; i++) {
      const xPos = chartArea.left + padding + i * step;
      ctx.beginPath();
      ctx.moveTo(xPos, chartArea.top - padding * 2);
      ctx.lineTo(xPos, chartArea.bottom + padding * 2);
      ctx.stroke();
    }
    ctx.restore();
  },
};

const ChartRow: React.FC<ChartRowProps> = memo(({ employee }) => {
  const data = useMemo(() => ({
    labels: [''],
    datasets: [
      {
        label: 'Buying Committee',
        data: [employee.committee_probability],
        backgroundColor: '#83AFD3',
        barThickness: 25,
        barPercentage: 1,
      },
      {
        label: 'Buying Cluster',
        data: [employee.cluster_probability],
        backgroundColor: '#0860A8',
        barThickness: 25,
        barPercentage: 1,
      },
    ],
  }), [employee]);

  const options = useMemo<ChartOptions<'bar'>>(() => ({
    indexAxis: 'y',
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          title: () => `${employee.first_name} ${employee.last_name}`,
          label: (context: any) => `${context.dataset.label || ''} ${context.raw || 0}%`,
        },
        backgroundColor: '#596177E5',
        titleColor: '#EEEEEE',
        bodyColor: '#EEEEEE',
        titleFont: { size: 12, weight: 400, lineHeight: '16px', family: 'Inter' },
        bodyFont: { size: 12, weight: 400, lineHeight: '16px', family: 'Inter' },
        bodySpacing: 10,
        displayColors: true,
        boxWidth: 18,
        boxPadding: 6,
        caretSize: 6,
        caretPadding: 8,
        cornerRadius: 6,
        padding: 10,
      },
    },
    scales: {
      x: {
        border: { display: false },
        max: 100,
        ticks: { display: false },
        grid: { display: false },
      },
      y: {
        border: { display: false },
        display: false,
        grid: { drawOnChartArea: false },
      },
    },
    layout: { padding: { top: 10, bottom: 5 } },
  }), [employee]);

  return (
    <TableRow sx={{
      borderBottom: '1px solid #ddd',
      '&:last-child': { borderWidth: 0 },
      
    }}>
      <TableCell sx={{ padding: 0, width: '50%', borderBottom: 'none' }}>
        <Box sx={{fontWeight: 700, fontSize: "16px", lineHeight: "24px", color: '#1F2932', display: 'grid', gridTemplateColumns: '8% 32% 55%', alignItems: 'center', gap: '10px', padding: '12px' }}>
          <Person2OutlinedIcon fontSize="medium" sx={{ color: '#1C3C6C', justifySelf: 'center' }} />
          <Typography variant="subtitle1" sx={{ color: '#1F2932', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {`${employee.first_name} ${employee.last_name}`}
          </Typography>
          <EllipsisTooltip>
              {employee.job_title}
          </EllipsisTooltip>
        </Box>
      </TableCell>
      <TableCell sx={{ padding: 0, width: '58%', borderBottom: 'none' }}>
        <Box height={72} width="100%" sx={{ overflow: 'visible' }}>
          <Bar plugins={[extendBarsPlugin]} data={data} options={options} />
        </Box>
      </TableCell>
    </TableRow>
  );
});

interface ChartTableProps {
  items: ChartItem[];
}

export const ChartTable: React.FC<ChartTableProps> = ({ items }) => (
  <Box>
      {items.length > 0 ? (
  <TableContainer component={Box} sx={{ width: '100%', border: '1px solid #ECECEC', paddingBottom: 3, borderRadius: '8px', overflow: 'hidden', gap: 3, boxShadow: '0px 4px 8px 0px #1976D205' }}>
    <Table sx={{ width: '100%', tableLayout: 'fixed' }}>
      <TableBody>
        <TableRow sx={{ backgroundColor: 'white' }}>
          <TableCell sx={{ fontSize: '14px', paddingTop: 2, paddingLeft: 3, paddingBottom: 1, width: '45%', fontWeight: 500, lineHeight: '26px', border: 'none', letterSpacing: '0.25px', color: '#3F4041B2' }}>
            Employee information
          </TableCell>
          <TableCell sx={{ fontSize: '14px', paddingTop: 2, paddingLeft: 0, paddingBottom: 1, border: 'none', fontWeight: 500, lineHeight: '26px', letterSpacing: '0.25px', color: '#3F4041B2', width: '55%' }}>
            Membership probability
          </TableCell>
        </TableRow>
        {items.map((employee, index) => <ChartRow key={index} employee={employee} />)}
      </TableBody>
    </Table>
    </TableContainer>
    ) : (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="170px"
        sx={{
          borderRadius: '8px',
        }}
      >
        <Typography
          variant="body1"
          sx={{
            color: '#697184',
            fontSize: '16px',
            fontWeight: 500,
          }}
        >
          No data available
        </Typography>
      </Box>
    )}
  </Box>
);
