import React, { useState, useRef, useEffect } from 'react';
import { TextField, Typography, Box, Stack, CircularProgress, FormHelperText } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { BaseModal } from './BaseModal';
import { CustomButton } from './CustomButton';
import { AnnotationImg } from '../images/AnnotatioImg';
import { useCreateCampaignMutation, useUploadCampaignFileMutation } from '../../api/companyApi'
import { useCompany } from '../../hooks/useCompanyHook';
import { useHandleError } from 'hooks/useHandleError';
import { useHandleSuccess } from 'hooks/useHandleSuccess';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks';
import { RootState } from 'app/store';
import { selectIsInit } from 'features/authSlice';
import { sseManager } from 'utils/useSSEEvent';
import { baseUrl } from 'api/baseQuery';

interface NewCampaignUploadModalProps {
  open: boolean;
  onClose: () => void;
  onComplete: () => void;
  isCampaignExist: boolean; 
}
type FieldErrorMap = Record<string, string | undefined>;

export const NewCampaignUploadModal: React.FC<NewCampaignUploadModalProps> = ({ open, onClose, onComplete, isCampaignExist }) => {
  const dispatch = useAppDispatch();
  const { updateCompany } = useCompany();
  const [formData, setFormData] = useState({
    company_name: '',
    company_abstract: '',
    target_audience: '',
  });
  const token = useAppSelector((state: RootState) => state.auth.accessToken);
  const refreshToken = useAppSelector((state: RootState) => state.auth.refreshToken);
  const showInitModal = useAppSelector(selectIsInit);
  const campaignId = useAppSelector((state) => state.company.company);
  const [uploadedFile, setUploadedFile] = useState<string | null>(null);
  const [fieldErrors, setFieldErrors] = useState<FieldErrorMap>({});
  const [globalErrors, setGlobalErrors] = useState<string | null>(null);
  const [isClosedByComplete, setIsClosedByComplete] = useState(false);
  const [newCampaignid, setNewCamaignid] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [progress, setProgress] = useState<number>(0);
  const [isFileUploaded, setIsFileUploaded] = useState(false);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [createCampaign, { isSuccess: isCampaignCreated, data: createCampaignResult, isLoading: creatingCampaign, error: campaignError, reset: resetCampaignError }] = useCreateCampaignMutation();
  const [uploadFile, { isSuccess: uploadSuccess, isLoading: uploadingFile, error: fileError, reset: resetFileError }] = useUploadCampaignFileMutation();
  
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setFieldErrors((prev) => {
      const { [name]: _, ...rest } = prev;
      return rest;
    });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;
    if (event.target.files && event.target.files.length > 0 && file) {
        setUploadedFile(file.name);
        uploadFile({ campaign_id: newCampaignid, file })
    }
  };

  const validateAndOpenFileDialog = () => {
    const { company_name, company_abstract, target_audience } = formData;
    const newFieldErrors: FieldErrorMap = {};
    if (!company_name) newFieldErrors.company_name = 'Company name is required';
    if (!company_abstract) newFieldErrors.company_abstract = 'Company abstract is required';
    if (!target_audience) newFieldErrors.target_audience = 'Target audience is required';
    setError(null);
    if (Object.keys(newFieldErrors).length > 0) {
      setFieldErrors(newFieldErrors);
      return;
    }
    createCampaign(formData);
  };

  useHandleError(campaignError, {
    setFieldErrors,
    setGlobalErrors,
    reset: resetCampaignError,
  });
  useHandleError(fileError, {
    setFieldErrors,
    setGlobalErrors,
    reset: resetFileError,
  });

  useHandleSuccess(isCampaignCreated, () => {
    if (createCampaignResult?.campaign_id) {
      setNewCamaignid(createCampaignResult.campaign_id);
      fileInputRef.current?.click();
    }
  });

  useHandleSuccess(uploadSuccess, () => {
    setIsFileUploaded(true);
  });


  useEffect(() => {
    if (isClosedByComplete) {
      setFormData({
        company_name: '',
        company_abstract: '',
        target_audience: '',
      });
      setUploadedFile(null);
      setIsFileUploaded(false);
      setProgress(0);
      setGlobalErrors(null);
      setFieldErrors({});
      setIsClosedByComplete(false);
    }
  }, [isClosedByComplete]);

  const handleCompleteAndClose = () => {
    updateCompany(newCampaignid);
    setIsClosedByComplete(true);
    onComplete();
    onClose();
  };

  useEffect(() => {
    if (!newCampaignid || !isFileUploaded || !token) {
      return;
    }

    const handleMessage = (data: any) => {
      const { status, progress } = data;
      setProgress(progress);
  
      if (status === 'failed') {
        setGlobalErrors("Something went wrong during the analysis. Please try to upload data again");
        setIsFileUploaded(false);
        setUploadedFile(null);
        setProgress(0);
      }
  
      if (status === 'completed' && progress === 100) {
        setTimeout(() => {
          handleCompleteAndClose();
        }, 500);
      }
    };

    const handleError = (error: any) => {
      console.error('SSE Error:', error);
    };

    sseManager.connect({
      url: `${baseUrl}/campaigns/${newCampaignid}/sse-status`,
      token,
      refreshToken,
      onMessage: handleMessage,
      onError: handleError,
      dispatch: dispatch
    });
    
    return () => {
      sseManager.close(); 
    };
  }, [newCampaignid, isFileUploaded, token, onClose]);
  
  useEffect(() => {
    if (isCampaignCreated || uploadSuccess) {
      setGlobalErrors(null);
      setFieldErrors({});
    }
  }, [isCampaignCreated, uploadSuccess]);
  return (
    <BaseModal
      open={open || (showInitModal && isCampaignExist)}
      onClose={onClose}
      title={"New campaign"}
      width={{ xs: '90%', sm: 490, md: 490 }}
    >
      <Stack gap={3}>
        <Typography variant="body1" sx={{ color: "text.secondary" }}>
          Please complete steps below to start identifying the buying 
          committee and cluster members of your target company
        </Typography>
        <Stack gap={2}>
          <Typography variant='subtitle1' sx={{fontWeight: 500, color: "custom.textDarkBlue"}}>
            Provide your client’s company name, product or service abstract, and target audience
          </Typography>
          <Box display="flex" alignItems="center" gap={1}>
            <AnnotationImg/>
            <Typography variant="body2" sx={{ color: "custom.textDarkBlue" }}>
              We use this data to provide best analysis results
            </Typography>
          </Box>
        </Stack>
      
        <Stack gap={4} pt={2}>
          <Box sx={{ position: 'relative', width: '100%' }}>
            <TextField
              fullWidth
              name="company_name"
              placeholder=" e.g., INFUSE"
              label="Client’s company name*"
              value={formData.company_name}
              variant="outlined"
              error={!!fieldErrors.company_name}
              helperText={fieldErrors.company_name}
              onChange={handleInputChange}
            />
            <FormHelperText
              sx={{
                position: 'absolute',
                bottom: -20 ,
                right: 0,
                textAlign: 'right',
                fontSize: "12px",
                lineHeight: "20px",
                color: formData.company_name.length > 100 ? 'error.main' : '#00000099',
              }}
            >
            {`${formData.company_name.length}/100`}
          </FormHelperText>
          </Box>
          <Box sx={{ position: 'relative', width: '100%' }}>
            <TextField
              fullWidth
              minRows={4}
              maxRows={4}
              label="Client’s abstract*"
              variant="outlined"
              error={!!fieldErrors.company_abstract}
              helperText={fieldErrors.company_abstract}
              name="company_abstract"
              placeholder=" e.g., INFUSE is a global high-performance demand partner delivering demand strategies, programs, and outcomes for the most admired B2B brands"
              value={formData.company_abstract}
              onChange={handleInputChange}
              InputProps={{
                rows: 4,
                multiline: true,
                inputComponent: 'textarea',
                style: {
                  overflow: 'auto',
                },
              }}
            />
            <FormHelperText
              sx={{
                position: 'absolute',
                bottom: -20 ,
                right: 0,
                textAlign: 'right',
                fontSize: "12px",
                lineHeight: "20px",
                color: formData.company_abstract.length > 1000 ? 'error.main' : '#00000099', 
              }}
            >
            {`${formData.company_abstract.length}/1000`}
            </FormHelperText>
          </Box>
          <Box sx={{ position: 'relative', width: '100%' }}>
            <TextField
              fullWidth
              name="target_audience"
              placeholder=" e.g., Demand specialists, marketers, sales, executives. Manager+ seniority. Located in NOAM, EMEA, LATAM, APAC."
              value={formData.target_audience}
              error={!!fieldErrors.target_audience}
              helperText={fieldErrors.target_audience}
              label="Client’s target audience*"
              variant="outlined"
              onChange={handleInputChange}
              InputProps={{
                rows: 4,
                multiline: true,
                inputComponent: 'textarea',
                style: {
                  overflow: 'auto',
                },
              }}
            />
            <FormHelperText
              sx={{
                position: 'absolute',
                bottom: -20 ,
                right: 0,
                textAlign: 'right',
                fontSize: "12px",
                lineHeight: "20px",
                color: formData.target_audience.length > 300 ? 'error.main' : '#00000099',
              }}
            >
            {`${formData.target_audience.length}/300`}
            </FormHelperText>
          </Box>
        </Stack>
        <Stack pt={3}>
          <Typography variant='subtitle1' sx={{fontWeight: 500, color: "custom.textDarkBlue"}}>
            Upload the target company’s contacts list
          </Typography>
        </Stack>
        <Stack gap={1} sx={{ textAlign: "center" }}>
          {progress === 0 && !isFileUploaded ? (
            <>
              <Typography variant="body2" color="textSecondary">
                Max file size: <b>10MB.</b> Preferred format: <b>.csv</b> or <b>Excel file</b>
              </Typography>
              <CustomButton
                variant="contained"
                customColor="#1C3C6C"
                fullWidth
                type='button'
                onClick={validateAndOpenFileDialog}
                sx={{ height: "48px" }}
              >
                <UploadFileIcon fontSize='medium'/>            
                Upload contacts data
              </CustomButton>
            </>
          ) : (
            <Box display="flex" alignItems="center" justifyContent="center" py={1} gap={2}>
              <CircularProgress  size={20} sx={{ color: 'custom.subTitleModal' }} />
              <Typography variant='body1' color="text.secondary">
                {`Analysing ${uploadedFile}`}: <b>{`${progress}%`}</b>
              </Typography>
            </Box>
          )}
          <input
            type="file"
            hidden
            ref={fileInputRef}
            onChange={handleFileChange}
          />
            {globalErrors && (
          <Typography color="error" sx={{ }}>
            {globalErrors}
          </Typography>
        )}
        </Stack>
      </Stack>
    </BaseModal>
  );
};
