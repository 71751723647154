import React, { ReactNode, useState } from 'react';
import { Tooltip, Box } from '@mui/material';
import { isTextEllipsed } from 'utils/general_utils';

interface EllipsisTooltipProps {
    children: ReactNode;
}
  
export const EllipsisTooltip: React.FC<EllipsisTooltipProps> = ({ children }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipContent, setTooltipContent] = useState<string>('');

  const handleMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    const target = event.currentTarget;
    if (isTextEllipsed(target)) {
        setShowTooltip(true);
        setTooltipContent(target.textContent || ''); 
      } else {
        setShowTooltip(false);
      }
  };

  return (
    <Tooltip 
        title={showTooltip ? tooltipContent : ''}
        placement="bottom" 
        arrow 
        sx={{
            background: '#596177E5',
            fontSize: "12px",
            lineHeight: "16px",
            fontWeight: 400
    }}>
      <Box
        onMouseEnter={handleMouseEnter}
        sx={{
          display: 'block',
          maxWidth: '100%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
         {children}
      </Box>
    </Tooltip>
  );
};