import { useEffect, useState, useMemo } from 'react';
import { useGetCampaignMembersQuery } from 'api/companyApi';
import { CampaignMembersQuery } from 'types/apiTypes';

export const useCampaignMembers = (initialQuery: CampaignMembersQuery) => {
  const [query, setQuery] = useState<CampaignMembersQuery>(initialQuery);

  const skipQuery = !query.campaign_id?.trim();

  const { data: response, isLoading, isFetching } = useGetCampaignMembersQuery(query, {
    skip: skipQuery,
  });

  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);

  const members = useMemo(() => {
    if (!response?.items) return [];
    return response.items.map((member) => ({
      ...member,
      data_source: member.data_source === 'user_crm' ? 'Imported data' : 'Enriched data',
    }));
  }, [response]);

  useEffect(() => {
    if (initialQuery.campaign_id && initialQuery.campaign_id !== query.campaign_id) {
      setQuery((prevQuery) => ({
        ...prevQuery,
        ...initialQuery,
      }));
    }
  }, [initialQuery, query.campaign_id]);

  useEffect(() => {
    if (response) {
      setTotalPages(response.total_pages || 0);
      setTotalCount(response.count || 0);
    }
  }, [response]);

  const updateQuery = (updates: Partial<CampaignMembersQuery>) => {
    setQuery((prevQuery) => ({
      ...prevQuery,
      ...updates,
    }));
  };

  return {
    members,
    totalPages,
    totalCount,
    isLoading,
    isFetching,
    query,
    updateQuery,
  };
};
