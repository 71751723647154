import React from 'react';
import { CustomButton } from './CustomButton';

interface ClearButtonProps {
  onClick: () => void;
}

export const ClearDataButton: React.FC<ClearButtonProps> = ({ onClick }) => {
  return (
    <CustomButton onClick={onClick} 
                  customColor='' 
                  sx={{border: "1px solid #1D3E70"}} 
                  textColor="#1D3E70" 
                  customSize='m' 
                  variant="outlined">
      Clear Data
    </CustomButton>
  );
};
