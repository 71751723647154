import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Layout from 'layout/Layout';
import PrivateRoute from 'components/features/PrivateRoute';
import { CompanyView } from 'pages/CompanyView';
import { LoginView } from 'pages/Login';
import LoginLayout from 'layout/LoginLayout';

export const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route 
        path="/login" 
        element={
          <LoginLayout>
            <LoginView />
          </LoginLayout>
      } />
      <Route
        path="/"
        element={
          <PrivateRoute>
            <Layout />
          </PrivateRoute>  
          }
      >
        <Route index element={<CompanyView />} />
      </Route>
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
