import { fetchBaseQuery, BaseQueryFn, FetchBaseQueryError, FetchArgs } from '@reduxjs/toolkit/query';
import { resetAuth, setAuthTokens } from 'features/authSlice';
import { RootState } from 'app/store';

const baseUrl = `${process.env.REACT_APP_BASE_URL}/api`;

const fetchNewAccessToken = async (refreshToken: string) => {
  try {
    const response = await fetch(`${baseUrl}/auth/refresh-token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ refresh_token: refreshToken }),
    });

    if (!response.ok) {
      console.error(`Token error: ${response.status}`);
      return null;
    }
    return await response.json();
  } catch (error) {
    console.error('Network error:', error);
    return null;
  }
};

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  const state = api.getState() as RootState;
  const accessToken = state.auth.accessToken;
  let result = await fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      if (accessToken) {
        headers.set('authorization', `Bearer ${accessToken}`);
      }
      return headers;
    },
  })(args, api, extraOptions);
  if (result.error?.status === 401) {
    const refreshToken = state.auth.refreshToken;
    if (refreshToken){
      try {
        const { access_token: newAccessToken, refresh_token: newRefreshToken } = await fetchNewAccessToken(refreshToken);
        if (newAccessToken) {
          api.dispatch(setAuthTokens({ accessToken: newAccessToken, refreshToken: newRefreshToken })); 

        result = await fetchBaseQuery({
          baseUrl,
          prepareHeaders: (headers) => {
            headers.set('authorization', `Bearer ${newAccessToken}`);
            return headers;
          },
        })(args, api, extraOptions);
        } else {
          api.dispatch(resetAuth());
        }
      } catch (error) {
        console.error('Failed to refresh token:', error);
        api.dispatch(resetAuth());
      }

    } else {
      api.dispatch(resetAuth());
    }

  }
  return result;
};

export { baseUrl, baseQueryWithReauth, fetchNewAccessToken };
