import React from 'react';
import { AppBar, Toolbar, Box } from '@mui/material';
import { useAppSelector } from 'hooks/useReduxHooks';
import { ParentImportFileComponent } from './ParentImportFile';
import { ParentClearDataComponent } from './ParentClearData';
import { CustomLogo } from 'components/images/Logo';

export const Header: React.FC = () => {
  const isCompanyLoad = useAppSelector((state) => state.company.company);
  return (
    <AppBar position="static" 
            color="default" 
            sx={{
                background: "background.paper", 
                borderBottom: "1px solid #EAEAEA",
                height: "74px",
                boxShadow: '0px 5px 8.3px 0px #00000005',
                px: {xs: 2, sm: 6, md: 6, xl: 10},
                justifyContent: 'center', 
            }}>
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Box>
          <CustomLogo/>
        </Box>
        {isCompanyLoad && (
          <>
            <Box sx={{ 
                      display: 'flex', 
                      gap: "16px" 
                    }}>
              <ParentClearDataComponent/>
              <ParentImportFileComponent ButtonText="Import accounts data" isCampaignExist={false}/>
            </Box>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};
