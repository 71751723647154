import type { SVGProps } from 'react';


export function CustomLogo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="222" height="40" viewBox="0 0 222 40" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <path d="M109.767 28.1118C109.612 28.1118 109.509 27.9531 109.571 27.812L110.654 25.3625H110.493C110.409 25.3625 110.333 25.3133 110.299 25.2368L108.479 21.2011C108.415 21.0598 108.519 20.8999 108.674 20.8999H109.669C109.753 20.8999 109.83 20.9497 109.864 21.0269L111.242 24.1373L112.483 21.0341C112.516 20.953 112.594 20.8999 112.681 20.8999H113.637C113.791 20.8999 113.894 21.0567 113.833 21.1976L110.919 27.9825C110.885 28.0609 110.808 28.1118 110.723 28.1118H109.767Z" fill="#444445"/>
      <path d="M105.629 26.041C105.37 26.041 105.137 26.0078 104.931 25.9414C104.732 25.875 104.553 25.7854 104.393 25.6725C104.241 25.553 104.111 25.4201 104.005 25.274L103.894 25.7561C103.871 25.8529 103.785 25.9215 103.686 25.9215H102.874C102.756 25.9215 102.66 25.8259 102.66 25.7081V18.963C102.66 18.8451 102.756 18.7495 102.874 18.7495H103.791C103.909 18.7495 104.005 18.8451 104.005 18.963V21.5984C104.164 21.366 104.373 21.1734 104.632 21.0206C104.898 20.8613 105.227 20.7816 105.619 20.7816C106.083 20.7816 106.495 20.8978 106.854 21.1302C107.219 21.356 107.505 21.6681 107.71 22.0666C107.923 22.4584 108.029 22.9099 108.029 23.4213C108.029 23.9193 107.923 24.3676 107.71 24.766C107.505 25.1645 107.222 25.4766 106.864 25.7024C106.505 25.9282 106.093 26.041 105.629 26.041ZM105.31 24.8656C105.575 24.8656 105.808 24.8059 106.007 24.6863C106.213 24.5602 106.372 24.3908 106.485 24.1783C106.598 23.9592 106.655 23.7068 106.655 23.4213C106.655 23.1357 106.598 22.8834 106.485 22.6642C106.372 22.4384 106.213 22.2658 106.007 22.1463C105.808 22.0201 105.575 21.957 105.31 21.957C105.051 21.957 104.818 22.0201 104.613 22.1463C104.413 22.2658 104.254 22.4351 104.134 22.6543C104.022 22.8734 103.965 23.1258 103.965 23.4113C103.965 23.6969 104.022 23.9525 104.134 24.1783C104.254 24.3975 104.413 24.5668 104.613 24.6863C104.818 24.8059 105.051 24.8656 105.31 24.8656Z" fill="#444445"/>
      <g clipPath="url(#clip0_646_2460)">
      <path d="M123.597 11.8154H122.554C120.72 11.8154 120.337 12.5775 120.337 13.218V32.1377C120.337 32.7781 120.722 33.5403 122.554 33.5403H123.597C125.431 33.5403 125.813 32.7781 125.813 32.1377V13.218C125.813 12.5775 125.428 11.8154 123.597 11.8154Z" fill="#444445"/>
      <path d="M144.656 11.8442H143.791C141.957 11.8442 141.574 12.5919 141.574 13.2178V23.0327C141.574 23.2355 141.574 23.4268 141.577 23.6093C141.466 23.4355 141.352 23.2645 141.233 23.0964L134.532 12.844C134.281 12.4441 133.945 12.1717 133.533 12.0297C133.171 11.9051 132.724 11.8442 132.201 11.8442H130.919C129.085 11.8442 128.702 12.5919 128.702 13.2178V32.1376C128.702 32.7635 129.088 33.5111 130.919 33.5111H131.784C133.618 33.5111 134.001 32.7635 134.001 32.1376V22.1749C134.001 21.972 134.001 21.7808 133.998 21.6011C134.109 21.775 134.225 21.946 134.342 22.1141L141.192 32.5114C141.443 32.9113 141.779 33.1837 142.194 33.3257C142.556 33.4503 143.003 33.5111 143.525 33.5111H144.659C146.493 33.5111 146.879 32.7635 146.879 32.1376V13.2178C146.879 12.5919 146.493 11.8442 144.659 11.8442H144.656Z" fill="#444445"/>
      <path d="M163.773 12.4698C163.621 12.2119 163.314 11.9048 162.716 11.9048H151.386C150.851 11.9048 150.445 12.041 150.174 12.3105C149.902 12.58 149.765 12.9857 149.765 13.513V32.1373C149.765 32.7632 150.15 33.5108 151.985 33.5108H152.998C154.832 33.5108 155.218 32.7632 155.218 32.1373V24.8203H161.761C162.359 24.8203 162.666 24.5131 162.818 24.2523C162.996 23.9452 163.084 23.5076 163.084 22.9135V21.9978C163.084 21.4038 162.996 20.9662 162.815 20.659C162.663 20.4011 162.356 20.094 161.758 20.094H155.215V16.6311H162.719C163.317 16.6311 163.624 16.3239 163.776 16.0631C163.957 15.7559 164.042 15.3184 164.042 14.7243V13.8086C164.042 13.2146 163.954 12.777 163.773 12.4698Z" fill="#444445"/>
      <path d="M198.89 23.4934C198.329 22.6444 197.657 21.9836 196.889 21.5287C196.159 21.0969 195.21 20.6362 194.071 20.158C193.311 19.8306 192.727 19.5611 192.341 19.3582C191.997 19.1786 191.702 18.9409 191.459 18.6512C191.246 18.3933 191.141 18.0658 191.141 17.6515C191.141 17.35 191.29 17.0805 191.596 16.8285C191.822 16.643 192.33 16.4257 193.481 16.4257C194.202 16.4257 194.906 16.5358 195.572 16.756C195.721 16.8111 195.902 16.8864 196.124 16.9821C196.416 17.1095 196.62 17.1646 196.787 17.1646C197.143 17.1646 197.485 16.9647 197.832 16.5532C198.116 16.217 198.358 15.8171 198.554 15.368C198.759 14.8956 198.858 14.5015 198.858 14.1654C198.858 13.7075 198.577 13.2728 198.02 12.87C197.503 12.5049 196.795 12.2094 195.925 11.992C195.07 11.7776 194.108 11.6675 193.063 11.6675C191.784 11.6675 190.566 11.8906 189.447 12.3339C188.302 12.786 187.361 13.5018 186.651 14.4581C185.933 15.4259 185.571 16.6401 185.571 18.0658C185.571 19.7755 185.965 21.123 186.739 22.0735C187.493 22.995 188.536 23.7774 189.838 24.3917L191.232 24.9945C192.327 25.4321 193.124 25.829 193.597 26.171C193.994 26.4579 194.178 26.8201 194.178 27.3185C194.178 28.127 193.837 28.4139 193.577 28.5588C193.098 28.8254 192.502 28.9616 191.807 28.9616C191.162 28.9616 190.598 28.8805 190.13 28.7182C189.637 28.5472 189.073 28.3095 188.459 28.0053C187.84 27.6721 187.598 27.6025 187.425 27.6025C187.096 27.6025 186.76 27.7966 186.365 28.2111C186.041 28.553 185.758 28.9529 185.524 29.3991C185.27 29.8802 185.147 30.2917 185.147 30.6626C185.147 31.1842 185.489 31.6797 186.164 32.1347C186.8 32.5954 187.68 32.975 188.775 33.2619C189.859 33.5459 191.138 33.6908 192.581 33.6908C193.805 33.6908 194.965 33.459 196.03 33.0069C197.117 32.5432 198.016 31.784 198.706 30.7524C199.395 29.7208 199.745 28.3878 199.745 26.7911C199.745 25.4726 199.456 24.3627 198.887 23.4963L198.89 23.4934Z" fill="#444445"/>
      <path d="M216.529 29.2916C216.377 29.0337 216.07 28.7236 215.472 28.7236H207.285V24.7884H214.639C215.238 24.7884 215.545 24.4812 215.697 24.2205C215.878 23.9132 215.962 23.4757 215.962 22.8817V21.966C215.962 21.3719 215.875 20.9343 215.694 20.6272C215.542 20.3693 215.235 20.0621 214.636 20.0621H207.282V16.6311H215.44C216.039 16.6311 216.345 16.3239 216.497 16.0631C216.678 15.7559 216.763 15.3184 216.763 14.7243V13.8086C216.763 13.2146 216.675 12.777 216.494 12.4698C216.342 12.2119 216.035 11.9048 215.436 11.9048H203.449C202.915 11.9048 202.509 12.041 202.237 12.3105C201.966 12.58 201.829 12.9857 201.829 13.513V31.8417C201.829 32.372 201.966 32.7747 202.237 33.0443C202.509 33.3138 202.918 33.4499 203.453 33.4499H215.469C216.067 33.4499 216.374 33.1428 216.526 32.882C216.707 32.5748 216.792 32.1373 216.792 31.5432V30.6275C216.792 30.0335 216.704 29.5959 216.523 29.2887L216.529 29.2916Z" fill="#444445"/>
      <path d="M181.009 11.8447H180.025C178.19 11.8447 177.804 12.5923 177.804 13.2183V24.8356C177.804 25.1138 177.811 25.3427 177.804 25.5456V25.9773C177.804 26.015 177.804 26.0498 177.804 26.0874C177.804 27.745 176.452 29.0867 174.782 29.0867C173.111 29.0867 171.758 27.745 171.758 26.0874V25.6673C171.753 25.4557 171.758 25.2297 171.758 24.9834V20.6483V13.2125C171.755 12.5895 171.364 11.8505 169.541 11.8505H168.528C166.694 11.8505 166.311 12.5981 166.311 13.2241V26.1135C166.311 28.5013 167.126 30.3878 168.729 31.7179C170.444 33.1407 172.138 33.6942 174.767 33.6942C177.396 33.6942 179.09 33.1407 180.804 31.7179C182.411 30.3878 183.223 28.5013 183.223 26.1135V13.2241C183.223 12.5981 182.837 11.8505 181.003 11.8505L181.009 11.8447Z" fill="#444445"/>
      <path d="M167.511 7.70991H151.382C150.191 7.70991 149.56 6.75365 149.972 5.57135C150.722 3.41828 153.088 1.6709 155.261 1.6709H171.39C172.582 1.6709 173.213 2.62718 172.801 3.80948C172.05 5.96255 169.684 7.70991 167.511 7.70991Z" fill="#1D3E70"/>
      <path d="M216.111 7.70991H199.982C198.79 7.70991 198.16 6.75365 198.571 5.57135C199.322 3.41828 201.688 1.6709 203.861 1.6709H219.99C221.181 1.6709 221.813 2.62718 221.401 3.80948C220.65 5.96255 218.284 7.70991 216.111 7.70991Z" fill="#2194D2"/>
      <path d="M191.81 7.70991H175.681C174.489 7.70991 173.858 6.75365 174.27 5.57135C175.021 3.41828 177.387 1.6709 179.559 1.6709H195.689C196.88 1.6709 197.511 2.62718 197.099 3.80948C196.349 5.96255 193.983 7.70991 191.81 7.70991Z" fill="#0860A8"/>
      </g>
      <mask id="mask0_646_2460"  style={{ maskType: "alpha" } as React.CSSProperties} maskUnits="userSpaceOnUse" x="-5" y="-9" width="48" height="48">
      <path d="M42.6711 14.8355C42.6711 27.9995 31.9995 38.6711 18.8355 38.6711C5.67153 38.6711 -5 27.9995 -5 14.8355C-5 1.67153 5.67153 -9 18.8355 -9C31.9995 -9 42.6711 1.67153 42.6711 14.8355Z" fill="#2194D2"/>
      </mask>
      <g mask="url(#mask0_646_2460)">
      <path fillRule="evenodd" clipRule="evenodd" d="M26.3261 24.7921L27.5377 35.1773H22.5278L24.6451 26.7334C24.8703 25.8354 25.5083 25.1276 26.3261 24.7921ZM27.8517 24.5796H33.8396C35.2829 24.5796 36.4977 25.6599 36.6662 27.0934L38.2802 40.8225L30.2241 42.6282L27.8517 24.5796Z" fill="#1C3C6C"/>
      <path d="M11.7627 18.6262C11.7627 21.4061 9.50917 23.6597 6.72926 23.6597C3.94936 23.6597 1.6958 21.4061 1.6958 18.6262C1.6958 15.8463 3.94936 13.5928 6.72926 13.5928C9.50917 13.5928 11.7627 15.8463 11.7627 18.6262Z" fill="#1C3C6C"/>
      <path d="M35.8116 18.6262C35.8116 21.4061 33.558 23.6597 30.7781 23.6597C27.9982 23.6597 25.7446 21.4061 25.7446 18.6262C25.7446 15.8463 27.9982 13.5928 30.7781 13.5928C33.558 13.5928 35.8116 15.8463 35.8116 18.6262Z" fill="#1C3C6C"/>
      <path d="M25.1851 13.035C25.1851 16.7416 22.3055 19.7463 18.7534 19.7463C15.2013 19.7463 12.3218 16.7416 12.3218 13.035C12.3218 9.32847 15.2013 6.32373 18.7534 6.32373C22.3055 6.32373 25.1851 9.32847 25.1851 13.035Z" fill="#FE8836"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M11.209 25.0708L10.3668 35.1773H12.8343L12.453 27.2882C12.4082 26.3623 11.9245 25.5574 11.209 25.0708ZM0.0180664 35.1772L7.63098 42.2925L9.67937 24.5804C9.6564 24.5799 9.63337 24.5796 9.61028 24.5796H3.69805C2.24188 24.5796 1.02059 25.6788 0.867743 27.1269L0.0180664 35.1772Z" fill="#1C3C6C"/>
      <path d="M21.2701 21.2231H16.7959L17.9144 22.901H20.1515L21.2701 21.2231Z" fill="#1C3C6C"/>
      <path d="M16.7959 25.4181L17.9144 22.9014H20.1515L21.2701 25.4181L19.033 29.6127L16.7959 25.4181Z" fill="#1C3C6C"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M9.11328 43.6442L11.3588 23.2745C11.4554 22.115 12.4247 21.2231 13.5882 21.2231H14.5592L19.0334 29.6122L23.5075 21.2231H23.9192C25.0827 21.2231 26.052 22.115 26.1486 23.2745L28.5581 43.6442H9.11328Z" fill="#FE8836"/>
      </g>
      <path d="M83.3761 33C82.6161 33 82 32.3704 82 31.5938V13.4062C82 12.6296 82.6161 12 83.3761 12H90.367C92.0697 12 93.4789 12.3 94.5945 12.9C95.7297 13.5 96.5811 14.31 97.1486 15.33C97.7162 16.35 98 17.52 98 18.84C98 20 97.726 21.1 97.178 22.14C96.6495 23.16 95.8177 24 94.6826 24.66C93.567 25.3 92.1285 25.62 90.367 25.62H87.0202V31.5938C87.0202 32.3704 86.4041 33 85.6441 33H83.3761ZM87.0202 21.54H89.9853C91.0031 21.54 91.737 21.3 92.1872 20.82C92.6569 20.32 92.8918 19.66 92.8918 18.84C92.8918 18 92.6569 17.34 92.1872 16.86C91.737 16.38 91.0031 16.14 89.9853 16.14H87.0202V21.54Z" fill="#444445"/>
      <path d="M69.8826 33.3C67.7992 33.3 66.0008 32.8587 64.4874 31.9761C62.9937 31.0739 61.834 29.8285 61.0086 28.2398C60.2027 26.6315 59.7998 24.7878 59.7998 22.7088C59.7998 20.6102 60.2027 18.7568 61.0086 17.1485C61.834 15.5402 62.9937 14.2849 64.4874 13.3827C66.0008 12.4609 67.7992 12 69.8826 12C72.477 12 74.6095 12.6669 76.2801 14.0006C77.5844 15.0146 78.4966 16.3556 79.0169 18.0235C79.2686 18.8309 78.6112 19.5903 77.7639 19.5903H74.9955C74.3368 19.5903 73.7996 19.1086 73.5019 18.5222C73.2615 18.0487 72.9297 17.6595 72.5064 17.3544C71.8185 16.8445 70.9242 16.5895 69.8236 16.5895C68.7622 16.5895 67.868 16.8445 67.1408 17.3544C66.4332 17.8448 65.8829 18.5508 65.4898 19.4727C65.1163 20.3749 64.9296 21.4536 64.9296 22.7088C64.9296 23.9445 65.1163 25.0134 65.4898 25.9156C65.8829 26.8178 66.4332 27.5141 67.1408 28.0044C67.868 28.4948 68.7622 28.7399 69.8236 28.7399C70.9242 28.7399 71.8185 28.5242 72.5064 28.0927C72.904 27.832 73.2228 27.4992 73.4628 27.0943C73.7871 26.5474 74.3106 26.0921 74.9474 26.0921H77.7443C78.5983 26.0921 79.2577 26.864 78.9764 27.6686C78.45 29.1745 77.5513 30.4142 76.2801 31.3877C74.6095 32.6626 72.477 33.3 69.8826 33.3Z" fill="#444445"/>
      <path d="M44.1678 33.3C43.4123 33.3 42.7998 32.6614 42.7998 31.8737V13.4263C42.7998 12.6386 43.4123 12 44.1678 12H50.251C51.6518 12 53.4905 12.2333 54.4828 12.6999C55.475 13.1461 56.2338 13.7851 56.7592 14.6169C57.2845 15.4283 57.5471 16.402 57.5471 17.538C57.5471 18.5929 57.3234 19.4854 56.8759 20.2157C56.4479 20.946 55.8642 21.514 55.1248 21.9197C54.3855 22.3051 53.5683 22.5283 52.6733 22.5891L53.1694 22.224C54.1423 22.2646 54.9886 22.5384 55.7085 23.0456C56.4284 23.5324 56.9926 24.1714 57.4012 24.9626C57.8098 25.7334 58.0141 26.5753 58.0141 27.4881C58.0141 28.6647 57.7417 29.6891 57.1969 30.5614C56.6716 31.4337 55.9031 32.1133 54.8913 32.6001C53.8796 33.0667 51.6908 33.3 50.251 33.3H44.1678ZM47.7904 29.1009H50.6596C51.4962 29.1009 52.1383 28.9081 52.5858 28.5227C53.0527 28.117 53.2862 27.5389 53.2862 26.7883C53.2862 26.0174 53.043 25.4291 52.5566 25.0234C52.0896 24.5974 51.4378 24.3844 50.6012 24.3844H47.7904V29.1009ZM47.7904 20.5809H50.251C51.0682 20.5809 51.6908 20.3983 52.1188 20.0331C52.5663 19.6477 52.79 19.0899 52.79 18.3596C52.79 17.6293 52.5663 17.0714 52.1188 16.686C51.6908 16.3006 51.0682 16.1079 50.251 16.1079H47.7904V20.5809Z" fill="#444445"/>
      <defs>
      <clipPath id="clip0_646_2460">
      <rect width="101.746" height="32.0179" fill="white" transform="translate(120.254 1.67285)"/>
      </clipPath>
      </defs>
    </svg>
  );
};
