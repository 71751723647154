import React, { useRef, useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { CompanyInfoProps } from 'types/companyTypes';

export const CompanyInfo: React.FC<CompanyInfoProps> = ({ name, size, contactsImported, industry }) => {
  const nameRef = useRef<HTMLDivElement>(null);
  const [isNameWrapped, setIsNameWrapped] = useState(false);

  useEffect(() => {
    const thresholds = [
      { mediaQuery: '(max-width: 1024px)', threshold: 159 },
      { mediaQuery: '(max-width: 1441px)', threshold: 430 },
      { mediaQuery: '(min-width: 1442px)', threshold: 800 },
    ];

    const currentThreshold = thresholds.find(({ mediaQuery }) =>
      window.matchMedia(mediaQuery).matches
    )?.threshold || 800;

    if (nameRef.current) {
      const { clientWidth } = nameRef.current;
      setIsNameWrapped(clientWidth > currentThreshold);
    }
  }, [name]); //TODO Rework

  const details = [
    {
      label: 'Company size',
      value: size.trim() !== '-' ? `${size} employees` : size.trim(),
    },
    {
      label: 'Contacts imported',
      value: contactsImported.trim() !== '-' 
        ? `${contactsImported} contacts` 
        : contactsImported, 
    },
    {
      label: 'Industry',
      value: industry,
    },
  ];

  return (
    <Box sx={styles.container}>
      <Box sx={styles.nameBox} ref={nameRef}>
        <Typography
          variant="h1"
          color="custom.textDarkBlue"
          sx={styles.nameTypography}
        >
          {name}
        </Typography>
      </Box>
      <Box sx={styles.detailsBox}>
        {details.map((item, index) => (
          <Box
            key={index}
            sx={{
              ...styles.detailItem,
              ...(isNameWrapped && index === 0
                ? { borderLeft: 'none', paddingLeft: 0 }
                : {}),
            }}
          >
            <Typography
              variant="body1"
              color="#1F293299"
              sx={styles.labelTypography}
            >
              {item.label}
            </Typography>
            <Typography
              color="custom.textDarkBlue"
              sx={styles.valueTypography}
            >
              {item.value}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 3,
    width: '100%',
  },
  nameBox: {
    flexBasis: 'auto',
    flexGrow: 0,
    flexShrink: 0,
    minWidth: { sm: '160px', lg: '366px' },
    maxWidth: '100%',
    textAlign: { xs: 'center', sm: 'left' },
    wordBreak: 'break-word',
    overflow: 'hidden',
  },
  nameTypography: {
    fontSize: { md: '64px' },
    lineHeight: { md: '75px' },
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    textAlign: 'left',
  },
  detailsBox: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    flexGrow: 1,
    alignItems: 'flex-start',
    gap: 3,
  },
  detailItem: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 'auto',
    borderLeft: '1px solid',
    borderColor: '#E0E0E0',
    pl: 3,
    gap: 1,
    display: 'flex',
    flexDirection: 'column',
    minWidth: '150px',
  },
  labelTypography: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  valueTypography: {
    fontSize: '24px',
    lineHeight: '36px',
    fontWeight: 500,
    whiteSpace: 'nowrap',
  },
};
