import React, { ReactNode } from 'react';
import { Box } from '@mui/material';
import { LoginHeader } from 'components/features/LoginHeader';

interface LoginLayoutProps {
    children: ReactNode;
  }

const LoginLayout: React.FC<LoginLayoutProps> = ({ children }) => {
  return (
    <Box>
      <LoginHeader />
      {children}
    </Box>
  );
};

export default LoginLayout;