import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import type { AuthState, SetAuthPayload } from 'types/authTypes';

const initialState: AuthState = {
  isInit: false,
  isAuthenticated: false,
  accessToken: null,
  refreshToken: null,
};

const loadAuthState = (): AuthState => {
  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');
  return accessToken && refreshToken
    ? {
        ...initialState,
        isAuthenticated: true,
        accessToken,
        refreshToken,
      }
    : initialState;
};

const authSlice = createSlice({
  name: 'auth',
  initialState: loadAuthState(),
  reducers: {
    setAuth: (state, action: PayloadAction<SetAuthPayload>) => {
      state.isAuthenticated = true;
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.isInit = true;
    },
    setAuthTokens: (state, action: PayloadAction<SetAuthPayload>) => {
      state.isAuthenticated = true;
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken; 
    },
    resetInit: (state) => {
      state.isInit = false;
    },
    resetAuth: (state) => {
      state.isAuthenticated = false;
      state.isInit = true;
      state.accessToken = null;
      state.refreshToken = null;
    }
  },
});

export const { setAuth, resetAuth, resetInit, setAuthTokens } = authSlice.actions;

export const selectAuth = (state: RootState) => state.auth.isAuthenticated;
export const selectIsInit = (state: RootState) => state.auth.isInit;

export default authSlice.reducer;