import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CompanyState } from '../types/companyTypes';

const initialState: CompanyState = {
  company: localStorage.getItem('campaignId') || '',
};

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setCompany: (state, action: PayloadAction<string>) => {
      state.company = action.payload;
      localStorage.setItem('campaignId', action.payload);
    },
    clearCompany: (state) => {
      state.company = '';
      localStorage.removeItem('campaignId');
    },
  },
});

export const { setCompany, clearCompany } = companySlice.actions;
export default companySlice.reducer;
