import React from 'react';
import { Box, Typography, Stack } from '@mui/material';
import { BaseModal } from './BaseModal';
import { CustomButton } from './CustomButton';
import { AnnotationImg } from '../images/AnnotatioImg';
import { useCompany } from 'hooks/useCompanyHook';
import { useClearUserGampaignMutation } from 'api/companyApi';
import { resetApiState } from 'app/store';
import { useAppDispatch } from 'hooks/useReduxHooks';
import { resetInit } from 'features/authSlice';

interface ClearDataModalProps {
  open: boolean;
  onClose: () => void;
}

export const ClearDataModal: React.FC<ClearDataModalProps> = ({ open, onClose }) => {
  const dispatch = useAppDispatch();
  const { clearCompanyData } = useCompany();
  const [clearUserCampaign] = useClearUserGampaignMutation();

  const handleClear = () => {
      dispatch(resetInit());
      clearUserCampaign().unwrap().then((response) => {
        dispatch(resetApiState());
      })
      .catch((error) => {
        console.error('Failed to clear campaign:', error);
      })
      .finally(() => {
        clearCompanyData();
        onClose();
      });
  };

  return (
    <BaseModal 
      open={open} 
      onClose={onClose} 
      title="Clear data" 
      width={{ xs: "70%", sm: 368, md: 368 }}>
      <Stack gap={3}>
        <Typography variant="subtitle1" sx={{ color: "text.secondary" }}>
          Are you sure you want to clear data?
        </Typography>
        <Box display="flex" alignItems="center" gap={1} py={1}>
          <AnnotationImg/>
          <Typography variant="body2" sx={{ color: "custom.textDarkBlue" }}>
            Nothing will be saved
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <CustomButton onClick={handleClear} 
                      variant="contained" 
                      customColor="#1C3C6C"
                      sx={{ height: "48px" }}
                      fullWidth>
          Clear Data
        </CustomButton>
      </Box>
      </Stack>
    </BaseModal>
  );
};
