import React from 'react';
import { Box, Typography, Stack } from '@mui/material';
import { ParentImportFileComponent } from './ParentImportFile';
import { StorageImg } from 'components/images/StorageImg';

interface NoDataProps {
  isCampaignExist: boolean;
}

export const NoDataPlaceholder: React.FC<NoDataProps> = ({isCampaignExist}) => {
  return (
    <>
      <Box 
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: 'calc(100vh - 74px)',
          minHeight: 400, 
          width: '100%',
          textAlign: 'center',
          color: 'text.secondary',
          padding: 2,
          boxSizing: 'border-box',
          overflow: 'hiden',
          gap: 4
        }}
      > 
          <Box 
            sx={{
              width: {  md: '141px' },
              height: { md: '141px' },
              textAlign: 'center',
            }}>
            <StorageImg/>
          </Box>
          <Stack gap={1} style={{maxWidth: "414px"}}>
            <Typography variant="h1" color="custom.textDarkBlue" >
              No contacts data
            </Typography>
            <Typography variant="subtitle1" color="text.secondary" pb={4}> 
            Start new analyses by adding client’s company data and importing target company’s contacts list
            </Typography>
            <ParentImportFileComponent ButtonText="Add company and contacts data" isCampaignExist={isCampaignExist}/>
          </Stack>

      </Box>
    </>
  );
};
