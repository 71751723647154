import React, { useState } from 'react';
import { Box } from '@mui/material';
import { ClearDataButton } from 'components/ui/ClearDataButton';
import { ClearDataModal } from 'components/ui/ClearDataModal';


export const ParentClearDataComponent: React.FC = () => {
  const [isClearDataModalOpen, setClearDataModalOpen] = useState(false);

  const openNewCampaignModal = () => {
    setClearDataModalOpen(true);
  };

  return (
    <Box>
      <ClearDataButton onClick={openNewCampaignModal} />
      <ClearDataModal 
        open={isClearDataModalOpen} 
        onClose={() => setClearDataModalOpen(false)}
      />
    </Box>
  );
};
