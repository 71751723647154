import React, { useState }  from 'react';
import { TextField, Box, CircularProgress, InputAdornment, useTheme, IconButton, Typography } from '@mui/material';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { useForm, Controller } from 'react-hook-form';
import { LoginFormData } from 'types/authTypes';
import { CustomButton } from 'components/ui/CustomButton';

type FieldErrors = Record<string, string>;
type GlobalError = string | null;

interface LoginFormProps {
  onSubmit: (data: LoginFormData) => void;
  loading: boolean;
  fieldErrors: FieldErrors;
  globalError: GlobalError;
  setFieldErrors: React.Dispatch<React.SetStateAction<FieldErrors>>;
  setGlobalErrors: React.Dispatch<React.SetStateAction<GlobalError>>;
}

export const LoginForm: React.FC<LoginFormProps> = ({ 
  onSubmit, 
  loading, 
  fieldErrors, 
  globalError, 
  setFieldErrors,
  setGlobalErrors 
}) => {
  const theme = useTheme();  
  const { handleSubmit, control,  formState: { errors }, clearErrors } = useForm<LoginFormData>();
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleFieldChange = (fieldName: 'email'|'password') => {
    setFieldErrors({});
    setGlobalErrors(null);
    clearErrors(fieldName);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)} 
      sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
    > 
      <Box gap="40px" paddingBottom="64px" display="flex" flexDirection="column">
        <Controller
          name="email"
          control={control}
          defaultValue=""
          rules={{ required: 'Email is required' }}
          render={({ field }) => (
            <TextField
              {...field}
              label="Email"
              placeholder="example@gmail.com"
              inputProps={{
                autoComplete: 'off',
                form: {
                  autoComplete: 'off',
                },
              }}
              onChange={(e) => {
                field.onChange(e);
                handleFieldChange('email');
              }}
              error={!!errors.email || !!fieldErrors.email} 
              helperText={errors.email?.message || fieldErrors.email}
              fullWidth
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          defaultValue=""
          rules={{ required: 'Password is required' }}
          render={({ field }) => (
            <TextField
              {...field}
              label="Password"
              placeholder='Password'
              type={showPassword ? "text" : "password"}
              onChange={(e) => {
                field.onChange(e);
                handleFieldChange('password');
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleTogglePasswordVisibility}
                    >
                      {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={!!errors.password || !!fieldErrors.password}
              helperText={errors.password?.message || fieldErrors.password}
              fullWidth
            />
          )}
        />
      </Box>
      <CustomButton
        type="submit"
        variant="contained"
        fullWidth
        customColor={theme.palette.primary.main} 
        customGradientColor={theme.palette.primary.dark}
        disabled={loading}
      >
        {loading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : 'Sign in'}
      </CustomButton>
      <Box minHeight={"55px"} >
        {globalError && <Typography color="error" mt={1}> {globalError}</Typography>}
      </Box>
    </Box>
  );
};
