import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth, baseUrl } from './baseQuery';
import type {
  CampaignRequest,
  CampaignResponse,
  FileUploadResponse,
  CampaignDetailsResponse,
  CampaignChartResponse,
  CampaignMembersResponse,
  CampaignMembersQuery,
  CampaignLatest,
  CampaignLatestClear,
} from 'types/apiTypes';
import { RequestMethods } from 'types/enums';

export const campaignsApi = createApi({
  reducerPath: 'campaignsApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    // POST /api/campaigns
    createCampaign: builder.mutation<CampaignResponse, CampaignRequest>({
      query: (body) => ({
        url: '/campaigns',
        method: RequestMethods.POST,
        body,
      }),
    }),

    // POST /api/campaigns/{campaign_id}/upload_file
    uploadCampaignFile: builder.mutation<FileUploadResponse, { campaign_id: string; file: File }>({
      query: ({ campaign_id, file }) => {
        const formData = new FormData();
        formData.append('file', file);

        return {
          url: `/campaigns/${campaign_id}/upload_file`,
          method: RequestMethods.POST,
          body: formData,
        };
      },
    }),

    // GET /api/campaigns/{campaign_id}
    getCampaignDetails: builder.query<CampaignDetailsResponse, { campaign_id: string }>({
      query: ({ campaign_id }) => ({
        url: `/campaigns/${campaign_id}`,
        method: RequestMethods.GET,
      }),
    }),

    // GET /api/campaigns/latest
    getUserGampaign: builder.query<CampaignLatest, void>({
      query: () => ({
        url: `/campaigns/latest`,
        method: RequestMethods.GET,
      }),
      transformResponse: (response: CampaignLatest): CampaignLatest  => {
        if (response.message) {
          return { error: response.message };
        }
        return {
          campaign_id: response.campaign_id
        };
      },
    }),

     // GET /api/campaigns/latest
     clearUserGampaign: builder.mutation<CampaignLatestClear, void>({
      query: () => ({
        url: `/campaigns/latest`,
        method: RequestMethods.DELETE,
      }),
    }),

    // GET /api/campaigns/{campaign_id}/members
    getCampaignMembers: builder.query<CampaignMembersResponse, CampaignMembersQuery>({
      query: ({ campaign_id, page, per_page, sort, order, is_enriched }) => ({
        url: `/campaigns/${campaign_id}/members`,
        method: RequestMethods.GET,
        params: { page, per_page, sort, order, is_enriched },
      }),
    }),

    getCampaignChart: builder.query<CampaignChartResponse, { campaign_id: string }>({
      query: ({ campaign_id }) => ({
        url: `/campaigns/${campaign_id}/chart`,
        method: RequestMethods.GET,
      }),
    }),
  }),
});

export const {
  useCreateCampaignMutation,
  useUploadCampaignFileMutation,
  useGetCampaignDetailsQuery,
  useGetUserGampaignQuery,
  useClearUserGampaignMutation,
  useGetCampaignMembersQuery,
  useGetCampaignChartQuery
} = campaignsApi;
