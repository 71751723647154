import React, { useState } from 'react';
import { Box } from '@mui/material';
import { ImportButton } from 'components/ui/ImportButton';
import { NewCampaignUploadModal } from 'components/ui/NewCampaignUploadModal';
import { useAppDispatch } from 'hooks/useReduxHooks';
import { resetInit } from 'features/authSlice';


interface ParentImportFileComponentProps {
  ButtonText: string;
  isCampaignExist: boolean;
}

export const ParentImportFileComponent: React.FC<ParentImportFileComponentProps> = ({ ButtonText, isCampaignExist }) => {
  const dispatch = useAppDispatch();
  const [isNewCampaignModalOpen, setIsNewCampaignModalOpen] = useState(false);

  const openNewCampaignModal = () => {
    setIsNewCampaignModalOpen(true);
  };
  
  const onCompleteCamign = () => {
    setIsNewCampaignModalOpen(false)
  };

  const onCloseModal = () => {
    setIsNewCampaignModalOpen(false)
    dispatch(resetInit());
  }

  return (
    <Box>
      <ImportButton onClick={openNewCampaignModal} ButtonText={ButtonText}  />
      <NewCampaignUploadModal 
        onComplete={onCompleteCamign}
        open={isNewCampaignModalOpen} 
        onClose={onCloseModal}
        isCampaignExist={isCampaignExist}
      />
    </Box>
  );
};
