import { Navigate } from 'react-router-dom';
import { useAppSelector } from 'hooks/useReduxHooks';
import { selectAuth } from 'features/authSlice';
import { history } from 'utils/history';

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const isLoggedIn = useAppSelector(selectAuth);
  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: history.location }} />;
  }

  return children;
};

export default PrivateRoute;